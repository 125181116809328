@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alkatra:wght@400;500;600;700&family=Arimo:ital,wght@1,700&family=Delicious+Handrawn&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;


@keyframes shake {
    0% { transform: translateX(0); }
    25% { transform: translateX(-40px); }
    50% { transform: translateX(40px); }
    75% { transform: translateX(-40px); }
    100% { transform: translateX(0); }
  }
  .animate-shake {
    animation: shake 0.3s linear;
  }
  